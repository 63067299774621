import Geocode from 'react-geocode';
import { GEOCODE_CONFIG } from './config';

class GeocodeManager {
	/// Call init to place a FirebaseAuthProvider component at the top level of your app
	static init() {
		// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
		Geocode.setApiKey(GEOCODE_CONFIG.apiKey);
		// set response language. Defaults to english.
		Geocode.setLanguage('en');
		// set response region. Its optional.
		// A Geocoding request with region=in (India) will return the Indian city.
		Geocode.setRegion('in');
		// set location_type filter . Its optional.
		// google geocoder returns more that one address for given lat/lng.
		// In some case we need one address as response for which google itself provides a location_type filter.
		// So we can easily parse the result for fetching address components
		// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
		// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
		Geocode.setLocationType('ROOFTOP');

		// Enable or disable logs. Its optional.
		Geocode.enableDebug();
	}

	static getAddressFromLatLng(lat, lng, onSuccess) {
		// Get formatted address, city, state, country from latitude & longitude when
		// Geocode.setLocationType("ROOFTOP") enabled
		// the below parser will work for most of the countries
		Geocode.fromLatLng(lat, lng).then(
			(response) => {
				const address = response.results[0].formatted_address;
				// let city;
				// let state;
				// let country;
				// for (let i = 0; i < response.results[0].address_components.length; i += 1) {
				// 	for (
				// 		let j = 0;
				// 		j < response.results[0].address_components[i].types.length;
				// 		j += 1
				// 	) {
				// 		switch (response.results[0].address_components[i].types[j]) {
				// 			case 'locality':
				// 				city = response.results[0].address_components[i].long_name;
				// 				break;
				// 			case 'administrative_area_level_1':
				// 				state = response.results[0].address_components[i].long_name;
				// 				break;
				// 			case 'country':
				// 				country = response.results[0].address_components[i].long_name;
				// 				break;
				// 			default:
				// 				break;
				// 		}
				// 	}
				// }
				if (onSuccess != null) onSuccess(address);
			},
			(error) => {
				console.error(error);
			},
		);
	}

	static getMapImageFromLatLong(lat, lng) {
		return `https://maps.googleapis.com/maps/api/staticmap?zoom=16&size=1024x1024&scale=2&maptype=roadmap&markers=color:red%7C${lat},${lng}&key=${GEOCODE_CONFIG.apiKey}`;
	}

	static getMapLinkFromLocation(location) {
		return `https://maps.google.com?q=${location.lat},${location.lng}`;
		// return "https://www.google.co.in/maps/@" + location.lat + "," + location.lng + ",18.02z";
	}
}

export default GeocodeManager;
