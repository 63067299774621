import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
// import useDarkMode from '../../../hooks/useDarkMode';
import styles from './Login.module.css';
import { login } from '../../../actions/auth';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	// const { darkModeStatus } = useDarkMode();

	const [firebaseResponse, setFirebaseResponse] = useState(null);
	const [mobileNumberInput, setMobileNumberInput] = useState(false);
	const [isNewUser] = useState(isSignUp);
	const [formData, setFormData] = useState({
		selectedCountry: { countryCode: 'in', dialCode: '91' },
		mobileNumber: '',
		otp: '',
	});

	const { selectedCountry, mobileNumber, otp } = formData;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onMobileNumberSubmit = async () => {
		const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			size: 'invisible',
			callback: async (response1) => {
				console.log(`applicationVerifier ${response1}`);
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				// this.onSignInSubmit();
			},
		});

		console.log('mobile number', mobileNumber);
		const response = await firebase
			.app()
			.auth()
			.signInWithPhoneNumber(`+${mobileNumber}`, appVerifier);

		if (response != null && response.verificationId != null) {
			setFirebaseResponse(response);
		}

		setMobileNumberInput(true);
	};

	const onOtpSubmit = async () => {
		const response = await firebaseResponse.confirm(otp);

		if (response) {
			const payload = {
				dialCode: selectedCountry.dialCode,
				authtoken: await response.user.getIdToken(),
			};

			const apiResponse = await dispatch(login(payload));
			if (apiResponse && apiResponse?.status) {
				navigate('/');
			}
		}
	};

	return (
		<PageWrapper
			title='Login'
			className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Logo width={200} />
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<div className='col-12'>
										{!mobileNumberInput ? (
											<FormGroup
												id='login-username'
												label='Your mobile number'>
												<PhoneInput
													country='in'
													inputProps={{
														name: 'mobileNumber',
														required: true,
														autoFocus: true,
													}}
													inputClass='form-control w-100'
													value={mobileNumber}
													onChange={(phone, country) => {
														setFormData({
															...formData,
															mobileNumber: phone,
															selectedCountry: country,
														});
													}}
												/>
											</FormGroup>
										) : (
											<FormGroup id='login-password' label='Enter OTP'>
												<OtpInput
													value={otp}
													onChange={(value) =>
														setFormData({
															...formData,
															otp: value,
														})
													}
													name='otp'
													numInputs={6}
													inputStyle={`form-control ${styles['otp-extra-width']}`}
													containerStyle='justify-content-center'
													separator={
														<span
															style={{
																width: '10px',
																display: 'inline-block',
															}}
														/>
													}
												/>
												{/* <Input
															type='password'
															value={otp}
															name='otp'
															onChange={(e) => onChangeValue(e)}
														/> */}
											</FormGroup>
										)}
									</div>
									<div className='col-12'>
										{!mobileNumberInput ? (
											<>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={onMobileNumberSubmit}>
													Continue
												</Button>
												<div
													style={{ marginTop: 20 }}
													id='recaptcha-container'
												/>
											</>
										) : (
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={onOtpSubmit}>
												Login
											</Button>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
