import React from 'react';
import firebase from 'firebase/app';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import 'firebase/auth';
import firebaseConfig from './config';

class FcmManager {
	/// Call init to place a FirebaseAuthProvider component at the top level of your app
	static init({ child }) {
		return (
			<FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
				{
					///
					child
				}
			</FirebaseAuthProvider>
		);
	}
}

export default FcmManager;
