import React, { useEffect, useState, useRef } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import Chat, { ChatGroup, ChatHeader, ChatListItem } from '../../../components/Chat';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
// import Avatar from '../../../components/Avatar';
// import showNotification from '../../../components/extras/showNotification';
// import CHATS from '../../../common/data/chatDummyData';
import Icon from '../../../components/icon/Icon';
import Badge from '../../../components/bootstrap/Badge';
import fetchChatCasesLIst, { fetchChatList } from '../../../actions/message';
import { socket } from '../../../components/socket/soket';

const CommonHeaderChat = () => {
	const [state, setState] = useState(false);
	const [showUserList, setShowUserList] = useState(false);
	const [msgCount] = useState(0);
	const [caseUserList, setCaseUserList] = useState([]);
	const [selectedChatUser, setSelectedChatUser] = useState({});
	const [messages, setMessages] = useState([]);
	const [textMessage, setTextMessage] = useState('');
	const [room, setRoom] = useState('');

	const getCaseUserList = async () => {
		const response = await fetchChatCasesLIst();
		if (response.status) {
			setCaseUserList(response.data);
		}
	};

	const getChatList = async (caseId) => {
		const response = await fetchChatList(caseId);
		if (response.status) {
			setMessages(response.data.reverse());
		}
	};

	useEffect(() => {
		getCaseUserList();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showUserList === true]);

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const joinRoom = (roomId) => {
		setRoom(roomId);
		if (roomId !== '') {
			const payload = { roomId };
			socket.emit('join-chat', payload);
		}
	};

	const sendMessage = (message) => {
		const data = {
			content: message,
			isMe: true,
			path: '',
			roomId: room,
			type: 5, // CASE Chat type
		};
		socket.emit('roomChat', data);
	};

	useEffect(() => {
		socket.on('receiveRoomChatMsgs', (data) => {
			if (data.userId === JSON.parse(localStorage.getItem('user'))?.Id) {
				data.message.isMe = true;
			} else {
				data.message.isMe = false;
			}
			setMessages((array) => [...array, data]);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	useEffect(() => {
		setTimeout(() => {
			console.log(socket.connected, socket.id, 'socket_connection_status');
		}, 1000);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('auth-token')]);

	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer'
				onClick={() => setShowUserList(!showUserList)}
				role='presentation'>
				<div className='position-relative'>
					<Badge color='dark' isLight>
						<Icon icon='chat-dots-fill' size='3x' color='primary' />
					</Badge>

					{!!msgCount && (
						<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
							{msgCount} <span className='visually-hidden'>unread messages</span>
						</span>
					)}
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				</div>
			</div>
			<OffCanvas
				id='chat'
				isOpen={showUserList}
				setOpen={setShowUserList}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setShowUserList} className='fs-5'>
					<div className='d-flex'>Chat</div>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Chat>
						{caseUserList &&
							caseUserList.map((value) => (
								<ChatListItem
									key={value.caseId}
									onClick={() => {
										joinRoom(value.caseId);
										getChatList(value.caseId);
										setState(true);
										setSelectedChatUser({
											name: value.Comment,
											caseId: value.caseId,
											image: `process.env.REACT_APP_IMAGE_BASE_URL}orig/${value.caseImg}`,
											chatId: value.chatUid,
										});
									}}
									src={`${process.env.REACT_APP_IMAGE_BASE_URL}orig/${value.caseImg}`}
									srcSet={`${process.env.REACT_APP_IMAGE_BASE_URL}orig/${value.ImageUrl}`}
									name={value.Comment}
									surname={' '}
									isOnline={USERS.CHLOE.isOnline}
									color={USERS.CHLOE.color}
									latestMessage={value.message.content}
								/>
							))}
					</Chat>
				</OffCanvasBody>
			</OffCanvas>

			<OffCanvas
				id='chat'
				isOpen={state}
				setOpen={setState}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setState} className='fs-5'>
					<ChatHeader to={`${selectedChatUser.name}`} />
				</OffCanvasHeader>
				<OffCanvasBody>
					<Chat>
						{messages.map((msg) => (
							<ChatGroup
								key={msg.id}
								messages={msg.message}
								user={msg.user}
								isReply={msg.message.isMe}
							/>
						))}
						<div ref={messagesEndRef} />
					</Chat>
				</OffCanvasBody>
				<div className='chat-send-message p-3'>
					<InputGroup>
						<Textarea
							value={textMessage}
							onChange={(e) => {
								setTextMessage(e.target.value);
							}}
						/>
						<Button
							color='info'
							icon='Send'
							onClick={() => {
								setTextMessage('');
								sendMessage(textMessage);
							}}>
							SEND
						</Button>
					</InputGroup>
				</div>
			</OffCanvas>
		</>
	);
};

export default CommonHeaderChat;
