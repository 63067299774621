import { AUTH_USER, AUTH_USER_FAIL, LOGIN_SUCCESS, LOGOUT } from '../actions/types';

const initialState = {
	loading: true,
	authenticated: false,
	user: null,
};

const auth = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case AUTH_USER:
			return {
				...state,
				loading: false,
				authenticated: true,
				user: payload,
			};
		case AUTH_USER_FAIL:
			return {
				...state,
				loading: false,
				authenticated: false,
			};
		case LOGIN_SUCCESS:
			localStorage.setItem('auth-token', payload.token);
			if (payload.partnerInfo != null) {
				localStorage.setItem('partnerInfo', JSON.stringify(payload.partnerInfo));
				localStorage.setItem('user', JSON.stringify(payload.user));
				localStorage.setItem('ngo_name', payload.partnerInfo.name);
				localStorage.setItem('ngo_logo', payload.partnerInfo.logoImg);
				localStorage.setItem('ngo_website', payload.partnerInfo.website);
			}
			return {
				...state,
				loading: false,
				authenticated: true,
				user: payload.user,
			};
		case LOGOUT:
			localStorage.clear();
			return {
				...state,
				loading: false,
				authenticated: false,
				user: null,
			};
		default:
			return state;
	}
};

export default auth;
