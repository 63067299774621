import { requestWithToken } from '../helpers/helpers';

export const fetchChatCasesLIst = async () => {
	try {
		const response = await requestWithToken('GET', '/chat/chat-cases');

		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const fetchChatList = async (caseId) => {
	try {
		const response = await requestWithToken(
			'GET',
			`/chat/roomChat/${caseId}?pageSize=100&pageNo=0`,
		);

		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export default fetchChatCasesLIst;
