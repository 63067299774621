const firebaseConfig = {
	apiKey: 'AIzaSyDE2FhCxhhueLG-_wHuPUVLB1fz33nj1bM',
	authDomain: 'resque-162005.firebaseapp.com',
	databaseURL: 'https://resque-162005.firebaseio.com',
	projectId: 'resque-162005',
	storageBucket: 'resque-162005.appspot.com',
	messagingSenderId: '66722002627',
	appId: '1:66722002627:web:36c830eaf30a4f109a9476',
};

export default firebaseConfig;
