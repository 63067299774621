import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useDispatch } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';

import { LOGIN_SUCCESS } from '../actions/types';
import { authUser } from '../actions/auth';
import showNotification from '../components/extras/showNotification';

const App = () => {
	getOS();
	const [userTokenType, setUserTokenType] = useState(true);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path];

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const checkUserLogin = async () => {
		const query = new URLSearchParams(window.location.search);
		const token = query.get('token');
		const agentId = query.get('agentId');
		const ticketId = query.get('ticketId');
		if (token && token.length) {
			localStorage.clear();
			localStorage.setItem('token_type_user', true);
			if (agentId && agentId.length) {
				localStorage.setItem('agentId', agentId);
			}
			if (ticketId && ticketId.length) {
				localStorage.setItem('ticketId', ticketId);
			}
			setUserTokenType(true);
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { token },
			});
			const response = await dispatch(authUser());
			if (!response?.status) {
				navigate('/login');
			}
		} else if (localStorage.getItem('token_type_user')) {
			localStorage.clear();
			showNotification('Session', 'Session time out', 'danger');
		} else {
			setUserTokenType(false);
			const response = await dispatch(authUser());
			if (!response?.status) {
				navigate('/login');
			}
		}
	};

	useEffect(() => {
		checkUserLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
							overflow: fullScreenStatus && 'scroll',
						}}>
						<Routes>
							{withOutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							{localStorage.getItem('token_type_user') || userTokenType ? (
								<> </>
							) : (
								<Route path='*' element={<Aside />} />
							)}
						</Routes>
						<Wrapper />
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
