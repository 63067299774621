import { requestWithoutToken, requestWithToken } from '../helpers/helpers';
import { AUTH_USER, AUTH_USER_FAIL, LOGIN_SUCCESS, LOGOUT } from './types';

// Authenticate User
export const authUser = () => async (dispatch) => {
	try {
		const response = await requestWithToken('GET', '/user/user');

		dispatch({
			type: AUTH_USER,
			payload: response.data.data,
		});

		return response.data;
	} catch (err) {
		// dispatch(error(err));
		dispatch({ type: AUTH_USER_FAIL });

		return err.response.data;
	}
};

// Login User
export const login = (formData) => async (dispatch) => {
	try {
		const response = await requestWithoutToken('POST', '/user/cp-login', formData);

		dispatch({
			type: LOGIN_SUCCESS,
			payload: response.data.data,
		});
		return response.data;
	} catch (err) {
		return err.response.data;
		// dispatch(error(err));
	}
};

// Logout User
export const logout = () => async (dispatch) => {
	dispatch({
		type: LOGOUT,
	});
};
